import { Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdMenu } from "react-icons/io";

import Drawer from "../../../shared/components/drawer";

import useMenuItems from "./linkItems";
import NavbarMobileButton from "./MobileNavbarButton";
import MobileNavbarItem from "./MobileNavbarItem";

import { NavbarMenuToggleProps } from "./index";

const MobileNav = ({ isOpen, onToggle }: NavbarMenuToggleProps) => {
  const { t } = useTranslation();
  const [pillarMenuItem] = useMenuItems();
  const btnRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Flex display={{ base: "flex", lg: "none" }}>
      <NavbarMobileButton
        active={isOpen}
        icon={IoMdMenu}
        ref={btnRef}
        title={t("main:menu.label")!}
        onClick={onToggle}
      />
      <Drawer btnRef={btnRef} isOpen={isOpen} size="full" onClose={onToggle}>
        <VStack alignItems="left">
          {[pillarMenuItem].map((item, i) => (
            <MobileNavbarItem key={i} {...item} handleClose={onToggle} />
          ))}
        </VStack>
      </Drawer>
    </Flex>
  );
};

export default MobileNav;
